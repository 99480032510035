<template>
<!-- APDS:
Tanto para jefe de zona como el  apd,
Si Expediente esta cerrado por CTD. (chkconf24),
Al EDITAR el expediente abre ventana (impresión de esta mañana de color rojo ) donde indica Imposible edición del expediente …
Solicita un campo de texto para la solicitud de apertura.
Botones de Cancelar y Aceptar
ACEPTAR
Guarda en las observaciones de Jefe de Zona o del APD, dependiendo del usuario una línea con el texto.
Esto genera de forma automática un aviso / incidencias. No hace falta hacer nada si se ha puesto que lo genere al introducir cualquier tipo de observaciones.
 

GESTION:
Si el Expediente está cerrado totalmente (chkconfcierre)
Al EDITAR el expediente abre ventana (impresión de esta mañana de color rojo ) donde indica Imposible edición del expediente …
Solicita un campo de texto para la solicitud de apertura.
Botones de Cancelar y Aceptar
ACEPTAR
Guarda en las observaciones de Cierre (chkdef)
¿?Envio Email .. .tengo que preguntar??
 

Si No esta cerrado totalmente (chkcierre), tanto para el ctd como para admon:
Al pulsar sobre el check:
Si esta Cerrado, lanza la ventana de apertura, preguntando motivo de reapertura (combo) y un texto.
Si esta abierto, lanza la ventana de cierre, preguntando motivo de cierre (combo) y un texto.
Se debe controlar que no se puede cerrar el ctd en caso de que existan facturas pendientes.                (aceptada=0).
No se puede cerrar admon si existen facturas aceptadas sin contabilizar.             (aceptada=1 and fra_conta_asi>0)
Los motivos de apertura y cierre están en datos iniciales.
 

En los dos casos ese texto y motivo se reflejan en las observaciones de cierre(chkdef). -->
  <div class="expCierreApertura" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      persistent>
      
        <template v-slot:controles="{}">

          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='stIni.titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">      
              <div class="contenedor">       
                <!-- PRESUPUESTOS RELACIONADOS CON EL PRESUPUESTO IMPORTADO -->    
        
                <div class="subtitulo">{{ opciones[opcionN].subtitulo }}</div>
                <div class="aviso">{{ opciones[opcionN].aviso }}</div>
                <div>{{ opciones[opcionN].lin2 }}</div>
                <div>{{ opciones[opcionN].lin3 }}</div>

                <div v-if="opcionN > 0">
                  <v-select
                    v-model="ct.motivo[2]"
                    v-bind="$select"
                    :label="opciones[opcionN].selectLabel"
                    :items="opciones[opcionN].items"
                    item-value="name"
                    item-text="name">
                  </v-select>
                </div>

                <!-- comentario apertura/solicitud -->
                <v-textarea
                  v-bind="$textarea"
                  v-model="ct.comentario[2]"
                  :label="opciones[opcionN].label"
                  rows="5"
                  no-resize>                  
                </v-textarea>

                <div class="pie">{{ opciones[opcionN].pie }}</div>
                <div>
                  <v-btn v-bind="$cfg.btn.busca_with_text" @click="aceptar">
                    Aceptar
                  </v-btn>

                  <v-btn v-bind="$cfg.btn.busca_with_text" @click="cancelar">
                    Cancelar
                  </v-btn>
                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  // -------------------------IMPORTACIONES
  import { mixM } from "@/mixins/mixM";  
  import baseHeader from "@/base/baseHeader";
  import dualTemplate from "@/components/dualTemplate";
  
  
  // -------------------------EXPORTACIONES
  export default {
    mixins: [mixM],
    components: { baseHeader, dualTemplate },
    props: {
      facturas: { type:Object, default:()=> {}},      
      opcion: { type: String, default: "" },      
    },

   
    data() {
      return {
        stIni: {
          api: "expCierreAperturaM",
          name:"expCierreAperturaM",
          titulo:"",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },
      
        opcionN: 1,        
        opciones: [
          {
            opcionN: 0,
            opcion: "aperturaAPD",
            titulo: "EXPEDIENTE CERRADO",
            subtitulo: "EDICION NO PERMITIDA",
            aviso: "Introduzca observaciones/incidencias producidas",
            label: "Observaciones/incidencias",
            pie:
              "Se emitirá un AVISO a SUPERVISORAS del CTD para su analisis y posible respuesta o REAPERTURA",
            obsOpcion: "SOLICITA APERTURA:",
          },

          {
            opcionN: 1,
            opcion: "aperturaCTD",
            titulo: "APERTURA EXPEDIENTE",
            subtitulo: "",
            aviso: "Seleccione el motivo e introduzca texto explicativo",
            label: "Texto explcativo",
            selectLabel: "Selecione motivo",
            pie: "",
            obsOpcion: "SOLICITA APERTURA:",
            items: this.$store.state.datos_iniciales.motivos_reapertura,
          },

          {
            opcionN: 2,
            opcion: "cierre",
            titulo: "CIERRE EXPEDIENTE",
            subtitulo: "",
            aviso: "Seleccione el motivo e introduzca texto explicativo",
            label: "Texto explcativo",
            selectLabel: "Selecione motivo",
            pie: "",
            obsOpcion: "CIERRE EXPEDIENTE:",
            items: this.$store.state.datos_iniciales.motivos_cierre,
          },
        ],
      };
    },



    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)", this.loaded);

        if (this.opcion=='') this.opcion=='aviso';
        if (this.opcion=='aviso') this.opcionN=0;
        if (this.opcion=='apertura') this.opcionN=1;
        if (this.opcion=="cierre") this.opcionN=2;
        
        // título de la cabecera
        this.stIni.titulo= this.opciones[this.opcionN].titulo;
      },


      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.aviso= ["", 'avisos', { fnt: "aviso", exp_id:0, txt:'' }, "expediente"];
        this.apiArgs.cierre= ["", 'cierre_email', { tp: "1", exp_id:0, txt:'' }, "expediente"];
      },
     

      aceptar() {
        console.log(this.ct);

        // APD
        if (this.opcionN == 0 && !this.ct.comentario[2]) {
          this.$root.$alert.open("Debe introducir texto explicativo", "info");
          return;
        }

        // ADMON/CTD (motivo y comentario)
        if (this.opcionN > 0 && (!this.ct.motivo[2] || !this.ct.comentario)) {
          this.$root.$alert.open("Debe introducir motivo y comentarlo", "info");
          return;
        }

        // CHECK FRAS  
        if (this.opcionN == 2 && this.checkFacturas() == false) return;
        this.peticionOK();
      },


      cancelar() {
        this.$emit("onCierreApertura", { obs: "" });      
      },


      checkFacturas() {
        if (!Array.isArray(this.facturas)) return;

        this.facturas.forEach((pres) => {
          if (pres.aceptada != 1 || pres.fra_conta_asi < 0) {
            this.$root.$alert.open(
              "Cierre no permitido (Existen presupuestos sin aceptar o contabilizar)",
              "error"
            );
            return false;
          }
        });

        return true;
      },


      async peticionOK() {
        this.$set(this.ct.motivo, 2, (this.ct.motivo[2]>''? `"MOTIVO:${this.ct.motivo[2]}` : ''));
        let obs = this.opciones[this.opcionN].obsOpcion + " " + this.ct.motivo[2] + " "+ this.ct.comentario[2];

        if (this.opcionN>0 || this.app=='1') {
            if (this.app=='1' && this.opcionN<'2') this.emailApertura(obs);

            this.$emit("onCierreApertura", { obs: obs });
            return;
        }

        console.log("----------------AWAIT  aviso ", this.record);
        
        // apiCall
        let param = { apiArg: this.apiArgs.aviso, args: { exp_id:this.record.id, txt:obs }};
        let apiResult = await this.$store.dispatch("apiCall", param);
                
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       
        this.$emit("onCierreApertura", { obs: obs });    
      },


      


      // email a supervisoras/admon
      async emailApertura(obs) {
        let param = { apiArg: this.apiArgs.cierre, args: { exp_id:this.record.id, txt:obs }};
        let apiResult = await this.$store.dispatch("apiCall", param);
                
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        console.log("------EMAIL APERTURA result", apiResult);
      },

      // },
      //
      // -------------------------COMPUTED
      //   computed: {
      //    OpcionN() {
      //       if (this.opcion == "solicitudApertura") {
      //         return 0
      //       }
      //       if (this.opcion == "apertura") {
      //         return 1;
      //       }
      //       return 0
      //     },
      //    computed(){
      //    this.Entorno.header.titulo = opcionN
      //   },
      //}
    },


    computed: {
      sch() {
        return {
          motivo: [ 'motivo', 'Motivo', "" ],
          comentario: [ 'comentario', 'Introduce información necesaria', "" ],
        }
      }
    }
  };

</script>



<style scoped>
.subtitulo {
  font-size: 1.5rem;
  color: red;
  text-align: center;
}
.aviso {
  font-size: 1rem;
  text-align: center;
}
.pie {
  font-size: 1rem;
  color: red;
  text-align: center;
  margin: 10px;
}
</style>

